import React from 'react';
import { useSelector } from 'react-redux';
import Axios from 'axios';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
//import MuiAlert from '@material-ui/lab/Alert';
// @material-ui/icons
import {PinDrop} from '@material-ui/icons';
import {Phone} from '@material-ui/icons';
import {Check} from '@material-ui/icons';
// core components
import GridContainer from 'componentsPage/Grid/GridContainer.js';
import GridItem from 'componentsPage/Grid/GridItem.js';
import InfoArea from 'componentsPage/InfoArea/InfoArea.js';
import Card from 'componentsPage/Card/Card.js';
import CardHeader from 'componentsPage/Card/CardHeader.js';
import CardBody from 'componentsPage/Card/CardBody.js';
import CardFooter from 'componentsPage/Card/CardFooter.js';
import CustomInput from 'componentsPage/CustomInput/CustomInput.js';
import Button from 'componentsPage/CustomButtons/Button.js';
import contactsStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/contactsStyle.js';

import city from 'assets/img/examples/office5.jpg';

const useStyles = makeStyles(contactsStyle);
/*
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}*/

export default function SectionContacts({ ...rest }) {
  const [checked, setChecked] = React.useState(false);
  const [nombres, setNombres] = React.useState('');
  const [apellidos, setApellidos] = React.useState('');
  const [correo, setCorreo] = React.useState('');
  const [mensaje, setMensaje] = React.useState('');
  const [errorEmail, setErrorEmail] = React.useState({ mostrar: false, tipo: 'warning', message: '' });

  const notaria = useSelector((state) => state.notaria.notaria);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorEmail({ mostrar: false, tipo: 'warning', message: '' });
  };

  const enviarCorreo = async (e) => {
    e.preventDefault();

    if (checked && nombres !== '' && correo !== '' && mensaje !== '') {
      try {
        let config = {
          method: 'POST',
          mode: 'cors',
          headers: { 'Access-Control-Allow-Origin': true, Authorization: 'Bearer ' + process.env.REACT_APP_TOKEN_API },
        };
        let data = {
          remitente_name: nombres + ' ' + apellidos,
          remitente_email: correo,
          descripcion: mensaje,
          destinatario: notaria.email_contactenos,
        };
        const resultado = await Axios.post(process.env.REACT_APP_UrlApi + 'MailContact', data, config);

        if (resultado.status === 200) {
          setNombres('');
          setCorreo('');
          setMensaje('');
          setErrorEmail({ mostrar: true, tipo: 'success', message: 'Correo Enviado, pronto le daremos una respuesta' });
        } else {
          setErrorEmail({ mostrar: true, tipo: 'warning', message: 'Sucedió un error, intentarlo mas tarde' });
        }
      } catch (eroror) {
        setErrorEmail({ mostrar: true, tipo: 'warning', message: 'Sucedió un error, intentarlo mas tarde' });
      }
    } else {
      setErrorEmail({ mostrar: true, tipo: 'warning', message: 'Campos vacíos' });
      setTimeout(() => {
        setErrorEmail({ mostrar: false, tipo: 'warning', message: '' });
      }, 3000);
    }
  };
  const classes = useStyles();

  return (
    <div className="cd-section" {...rest}>
      {/* Contact us 1 START */}
      <div className={classes.contacts + ' ' + classes.section} style={{ backgroundImage: `url(${city})` }}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8}>
              <h2 className={classes.title}>Información de contacto</h2>
              <h5 className={classes.description}>
                Necesita más información? Visite nuestras oficinas o comuníquese con nuestras líneas de atención al
                cliente para recibir información adicional.
              </h5>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d989.7965221814653!2d-73.12388111192017!3d7.104420230486248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e683fc89b0e4729%3A0x9b61e03995ceb341!2sNotar%C3%ADa%2011%20de%20Bucaramanga!5e0!3m2!1ses!2sco!4v1659725148767!5m2!1ses!2sco"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  ></iframe>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <InfoArea
                    className={classes.infoArea}
                    title="Estamos ubicados"
                    description={
                      <span className={classes.textWhite}>
                        Direccion: Avenida los Samanes No 9-35 Barrio Real de Minas Bucaramanga, Santander
                        <br /> Lunes a Viernes: 7:30am a 12:30pm y de 13:30pm a 17:30pm
                        <br /><b>Horario</b>
                        <br /> Sabados de Turno: 8:00am a 12:00pm
                      </span>
                    }
                    icon={PinDrop}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <InfoArea
                    className={classes.infoArea}
                    title="Llaménos"
                    description={
                      <span className={classes.textWhite}>
                        Teléfonos
                        <br />
                        607 6441454
                        <br />
                        +57 3002948002
                      </span>
                    }
                    icon={Phone}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} className={classes.mlAuto}>
              <Card className={classes.card1}>
                <form>
                  <CardHeader contact color="danger" className={classes.textCenter}>
                    <h4 className={classes.cardTitle}>Contáctenos por Correo electrónico</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Nombres"
                          id="first"
                          value={nombres}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => setNombres(e.target.value),
                            type: 'text',
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                          labelText="Apellidos"
                          id="last"
                          value={apellidos}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            onChange: (e) => setApellidos(e.target.value),
                            type: 'text',
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <CustomInput
                      labelText="Correo electrónico"
                      id="email-address"
                      value={correo}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        onChange: (e) => setCorreo(e.target.value),
                        type: 'email',
                      }}
                    />
                    <CustomInput
                      labelText="Mensaje"
                      id="message"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        multiline: true,
                        rows: 5,
                        onChange: (e) => setMensaje(e.target.value),
                        type: 'text',
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentBetween}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => setChecked(!checked)}
                          checkedIcon={<Check className={classes.checkedIcon} />}
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{ label: classes.label }}
                      label="Yo no soy un robot"
                    />
                    {errorEmail.mostrar && (
                      <Snackbar open={errorEmail.mostrar} autoHideDuration={6000} onClose={handleClose}>
                      </Snackbar>
                    )}
                    <Button color="danger" className={classes.pullRight} onClick={(e) => enviarCorreo(e)}>
                      Enviar Mensaje
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer className="ml-auto mr-auto">
            <GridItem xs={12} sm={8} md={12}>
              <h4 className="mb-4 text-white text-center" style={{ fontWeight: 500 }}>
                Atención al Público
              </h4>
            </GridItem>
            <GridItem xs={12} sm={8} md={4} className="text-center"></GridItem>
            <GridItem xs={12} sm={8} md={4} className="text-center">
              <a
                type="button"
                className="btn btn-rotate  btn-block btn-round mr-1 "
                style={{ backgroundColor: '#ec3642', color: 'white', borderColor: '#ec3642' }}
                href="/page/pqrs"
                onClick={(e) => e.preventDefault}
              >
                <i className="fa fa-hand-pointer-o" aria-hidden="true"></i>
                PQRS
              </a>
            </GridItem>
            <GridItem xs={12} sm={8} md={4} className="text-center"></GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
